import React, { useState } from "react"
import * as styles from './Wissen.module.scss';

import Layout from "../../page-components/layout";
import { graphql } from "gatsby";
import { Field, Formik } from "formik"

import "../../style/templates/wissen.scss";
import RcCard from "../../components/card/rcCard"
import { shuffleArray } from "../../helper/array"
import WissenContent from "./WissenContent"
import axios from "axios"
import { getStrapiUrl } from "../../helper/uri"
import { toast } from "react-toastify"
import RcFormErrors from "../../page-components/helper/rcFormErrors"

const WissenPage = ( { data } ) => {

  let furtherNodes = data.allWpWissen.nodes;
  furtherNodes = furtherNodes.filter(n => n.id !== data.wissen.id);
  shuffleArray(furtherNodes);
  furtherNodes = furtherNodes.slice(0, 4);

  const [ sent, setSent ] = useState(false);

  return (
    <Layout>

        <WissenContent data={data} />

        { data.wissen?.acf_elements?.newsletterdownload?.mediaItemUrl &&
          <div className={"container bg-gray w-100 py-5"}>
            <div className="row">

              <div className="col-12">

                {
                  sent &&
                  <div className={"col-12"}>
                    <p className={"my-4"}>Vielen Dank für die Anfrage. Bitte prüfen Sie Ihr Postfach.</p>
                  </div>
                }

                {!sent &&

                  <Formik
                    initialValues={
                      {
                        mail: '',
                        anrede: '',
                        vorname: '',
                        nachname: '',
                        telefon: '',
                        download: true,
                        newsletter: true
                      }
                    }
                    onSubmit={async (values, { setSubmitting }) => {

                      const v = {
                        ...values,
                        downloadLink: data.wissen?.acf_elements?.newsletterdownload?.mediaItemUrl,
                        downloadLabel: data.wissen?.acf_elements?.newsletterdownloadlabel
                      };

                      try {
                        await axios.post(getStrapiUrl() + "/newsletter-download", v);
                        setSubmitting(false);
                        setSent(true);
                      } catch (err) {
                        toast("Es ist ein unbekannter Fehler passiert.");
                      }

                    }}
                  >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        /* and other goodies */
                      }) => (
                      <form className={"rc-form row mb-5 px-4"} onSubmit={handleSubmit}>

                        <div className="col-12">
                          <h3>Anforderung der { data.wissen?.acf_elements.newsletterdownloadlabel }</h3>
                        </div>


                        <div className={"col-12 col-lg-8"}>
                          <div className="rc-form-control">
                            <label>E-Mail*</label>

                            <input
                              type="email"
                              name="mail"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.mail}
                            />
                          </div>

                          <div className="rc-form-control">
                            <label>Anrede</label>

                            <select
                              name={"anrede"}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.anrede}>

                              <option disabled={true}>bitte auswählen</option>
                              <option>Herr</option>
                              <option>Frau</option>

                            </select>

                          </div>

                          <div className="rc-form-control">
                            <label>Vorname</label>

                            <input
                              type="text"
                              name="vorname"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.vorname}
                            />
                          </div>

                          <div className="rc-form-control">
                            <label>Nachname</label>

                            <input
                              type="text"
                              name="nachname"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.nachname}
                            />
                          </div>

                          <div className="rc-form-control">
                            <label>Telefonnummer</label>

                            <input
                              type="tel"
                              name="telefon"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.telefon}
                            />
                          </div>

                          <div className="rc-form-control d-flex align-items-center">
                            <input
                              className={""}
                              type="checkbox"
                              name="download"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              checked={values.download}
                            />

                            <p style={ { fontWeight: 300, marginLeft: '.75rem'}}>
                               Ich ersuche um Zusendung Ihres Download-Links
                            </p>
                          </div>


                          <div className="rc-form-control mt-3 d-flex align-items-center">
                            <input
                              className={""}
                              type="checkbox"
                              name="newsletter"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              checked={values.newsletter}
                            />

                            <p style={ { fontWeight: 300, marginLeft: '.75rem'}}>
                              ich möchte in Zukunft den Reischauer Consulting Newsletter abonnieren und über aktuelle Wissensbeiträge, Veranstaltungen, Angebote/Nachfragen sowie Publikationen und Downloads informiert werden
                            </p>
                          </div>

                          <RcFormErrors touched={touched} errors={errors} className={"mb-4"} />

                          <button type="submit" disabled={isSubmitting}>Anfordern</button>

                        </div>
                      </form>
                    )}


                  </Formik>
                }


          </div>

            </div>
          </div>
        }

        {furtherNodes?.length > 0 &&
        <div className="container bg-primary-dark text-white w-100">
          <div className="py-5 mx-2">
            <div className="row">
              <div className="col-12">
                <h3 className={"d-block mb-2 mt-2 " + styles.furtherCaption}>
                  Weitere interessante Wissensbeiträge
                </h3>
              </div>
            </div>

            <div className="row">
              {furtherNodes.map(n => {
                  return <RcCard className={"my-3 text-dark"}
                                 to={"/wissen/" + n.slug}
                                 caption={n.title}
                                 img={n.acf_elements.header.localFile.childImageSharp.gatsbyImageData}/>;
                }
              )}
            </div>

          </div>
        </div>
        }
    </Layout>
  );
};

export default WissenPage;

export const query = graphql`
  query wpWissenQuery($id: String!) {
    wissen: wpWissen(id: { eq: $id }) {
      id
      slug
      title
      content
      
      date(formatString: "DD.MM.YYYY")
      
      acf_elements {
        header {
            mediaItemUrl        
                    
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
        }
        
        newsletterdownloadlabel
        newsletterdownload {
          mediaItemUrl
        }
        
        premium
        autor
        description
        subcaption
        category
      }
    }
    
    allWpWissen {
      nodes {
        id
        acf_elements {
          header {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        title
        slug
      }
    }
  }
`
