import React, { useContext, useEffect, useState } from "react"
import * as styles from "./Wissen.module.scss"
import RcButton from "../../components/button/rcButton"
import RcDefaultPageTeaser from "../../page-components/teaser/rcDefaultPageTeaser"
import { Link } from "gatsby"
import ChevronLeftSVG from "../../icons/fontawesome/light/chevron-left.svg"
import { AuthContext } from "../../provider/authProvider"
import * as axios from "axios"
import { getStrapiUrl } from "../../helper/uri"
import { toast } from 'react-toastify';
import { loadStripe } from "@stripe/stripe-js/pure"
import { STRIPE_PK } from "../../helper/constants"
import AboExplanation from "../../pages-parts/abo/aboExplanation"

const stripePromise = loadStripe(STRIPE_PK);

const WissenContent = ( { data } ) => {

  const authContext = useContext(AuthContext);
  const [ showPremiumWall, setShowPremiumWall ] = useState(data.wissen.acf_elements.premium);

  const handleAbo = async () => {
    const url = typeof window !== 'undefined' ? window.location.href : '';

    const stripe = await stripePromise;
    const res = await axios.post(getStrapiUrl() + "/checkout-abo", { redirectUrl: url });

    const result = await stripe.redirectToCheckout({
      sessionId: res.data.id,
    });

    if (result.error) {
      toast(result.error.message);
    }
  };

  useEffect(
    () => {
      if (authContext.currentUser?.premium) {
        setShowPremiumWall(false);
      }
    },
    [ authContext.currentUser ]
  );


  return <div className={"container bg-white p-relative " + (showPremiumWall ? styles.paywallContainer : "")} >

    { showPremiumWall &&
    <div className={styles.paywall} >
      <div className="row">
        <div className="col-12">
          <div className={"py-6 m-6 px-4 " + styles.paywallContent}>
            <h2>Premium Artikel</h2>
            <AboExplanation />

            <div className={"mt-3 d-flex flex-column flex-lg-row align-items-center"}>
              { !authContext.currentUser &&
                <div className={"d-flex"}>
                  <RcButton className={"mr-0 mr-lg-3 mb-3 mb-lg-0"}
                    onClick={authContext.showLoginModal}
                  >
                    Login
                  </RcButton>

                  <RcButton className={"mr-0 mr-lg-3 mb-3 mb-lg-0"}
                    onClick={authContext.showRegisterModal}
                  >
                    Registrieren
                  </RcButton>
                </div>
              }

              {authContext.currentUser &&
              <RcButton onClick={handleAbo}>
                Abo abschließen
              </RcButton>
              }
            </div>

          </div>
        </div>
      </div>
    </div>
    }

    <div className="row">

      <div className={"w-100"}>

        {/*<GatsbyImage image={ data.wissen.acf_elements.header?.localFile.childImageSharp.gatsbyImageData }*/}
        {/*             className={styles.backgroundImage} />*/}

        <RcDefaultPageTeaser style={"small"}>
          <div className="mx-lg-5 mx-2">
            <Link className={"mb-2 d-flex align-items-center text-dark"} to={"/wissen"}>
              <ChevronLeftSVG className={"mr-1 svgFill"} />
              Zurück
            </Link>

            <h3>{ data.wissen.title }</h3>

            <p className={"opacity-75 mt-2"}>
              veröffentlicht am {data.wissen.date}
              { data.wissen.acf_elements.autor &&
              <> von { data.wissen.acf_elements.autor}</>
              }
            </p>

            { data.wissen.acf_elements.subcaption &&
            <p>{data.wissen.acf_elements.subcaption}</p>}

            {data.wissen.acf_elements.digistorelink &&
            <a href={data.wissen.acf_elements.digistorelink} className={"btn btn-white-outline d-inline-block c-pointer mt-3"}>
              Jetzt kaufen
            </a>
            }

          </div>
        </RcDefaultPageTeaser>
      </div>


      <div className="col-12 pt-4 pt-lg-5 pb-4 pb-lg-6">
        <div dangerouslySetInnerHTML={ { __html: data.wissen.content }}
             className={"px-2 px-lg-5 " + styles.htmlContent} />
      </div>

      {/*{data.wissen.acf_elements.digistorelink &&*/}
      {/*  <div className={"py-5 w-100 " + styles.digistoreReminder}>*/}
      {/*    <div className="col-12 mx-5">*/}


      {/*      {data.wissen.acf_elements.digistorecalltoaction &&*/}
      {/*      <h4>{data.wissen.acf_elements.digistorecalltoaction}</h4>*/}
      {/*      }*/}

      {/*      <a href={data.wissen.acf_elements.digistorelink} className={"btn btn-primary d-inline-block c-pointer mt-2"}>*/}
      {/*        Jetzt kaufen*/}
      {/*      </a>*/}

      {/*    </div>*/}
      {/*  </div>*/}
      {/*}*/}

    </div>
  </div>
};

export default WissenContent;
