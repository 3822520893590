import React from 'react';
import { graphql, useStaticQuery } from "gatsby"

const AboExplanation = ( { className = '' }) => {

  const data = useStaticQuery(graphql`
    query AboExplanationQuery {
      wp {
        aboEventTexte {
          acf {
            abo
          }
        }
      }
    }
`);

  return <div className={className}
    dangerouslySetInnerHTML={
    {
      __html: data.wp.aboEventTexte.acf.abo
    }
  } />

};

export default AboExplanation;
